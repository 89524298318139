import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OldButtonDirective } from '@lorient-emploi-front/shared/ui/directives';
import { UserService } from './services/user.service';
import { ToastService, ToastType } from '@lorient-emploi-front/core/error-handler';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { LOGIN_URL } from './tokens/login-url.token';

function roleValidator(allowedRoles: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = allowedRoles.includes(control.value);
    return isValid ? null : { invalidRole: { value: control.value } };
  };
}

@Component({
  selector: 'lib-signin',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    OldButtonDirective
  ],
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  loginUrl = inject(LOGIN_URL);
  private _userService = inject(UserService);
  private _toastService = inject(ToastService);

  private readonly userChanged$ = toObservable(this._userService.createdUser);

  constructor() {
    this.userChanged$.pipe(
      tap((state) => {
        if (state.status === 'success') {
          this._toastService.show('Création réussie !', `Consultez votre adresse email ${state.data.email} !`, 10000, ToastType.Info);
        }
        if (state.status === 'error') {
          this._toastService.show('Erreur', state.message, 10000, ToastType.Error);
        }
      }),
      takeUntilDestroyed(),
    ).subscribe();}

  signinForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required, roleValidator(['organizations_manager', 'companies_manager'])]),
    relation_name: new FormControl('', [Validators.required]),
    siret: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  async submit() {
    if (!this.signinForm.valid) {
      return;
    }

    this._userService.create({
      name: this.signinForm.get('name')?.value as string,
      email: this.signinForm.get('email')?.value as string,
      password: this.signinForm.get('password')?.value as string,
      role: this.signinForm.get('role')?.value as string,
      relation_name: this.signinForm.get('relation_name')?.value as string,
      siret: this.signinForm.get('siret')?.value as string,
      description: this.signinForm.get('description')?.value as string,
    });
  }
}
