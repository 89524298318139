import { Injectable, inject, signal } from '@angular/core';
import { ApiService, RequestState } from '@lorient-emploi-front/core/http-client';
import { CreateUserForm } from '../models/create-user-form.model';
import { CreateUserResponse } from '../models/create-user-response.model';
import { ErrorResponse } from '@lorient-emploi-front/core/error-handler';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly _apiService = inject(ApiService);

  readonly createdUser = signal<RequestState<CreateUserResponse>>({ status: 'idle', data: null });

  create(createUserForm: CreateUserForm): void {
    this._apiService.post<CreateUserResponse, CreateUserForm>('/users', createUserForm).subscribe({
      next: (response) => {
        this.createdUser.set({
          status: 'success',
          data: response
        });
      },
      error: (err: { error: ErrorResponse }) => {
        this.createdUser.set({
          status: 'error',
          message: err.error.message,
          data: null
        });
      }
    });
  }
}
