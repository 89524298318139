<div class="items-center mb-100 px-24 py-64 bg-[url('assets/images/hero_background.png')] rounded-b-32 border-b-1 border-secondary-variant overflow-hidden">
    <h1 class="relative text-center text-black font-extrabold leading-43 text-32 mb-40 sm:mb-64 sm:text-60 sm:leading-72 w-fit m-auto max-w-550">
        Inscrivez votre <br>
        <span class="text-primary font-extrabold leading-43 text-32 sm:mb-64 sm:text-60 sm:leading-72">structure</span> !
    </h1>
</div>

<section class="relative flex flex-col-reverse md:flex-row justify-center md:items-start items-center">
    <form [formGroup]="signinForm">
        <div class="container px-20 md:items-center pb-110 max-w-700 mx-auto">
            <div class="text-left">
                <p class="font-bold text-32 mb-15 mt-10">Votre compte</p>

                <ul>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="text" name="name" formControlName="name" placeholder="Nom Prénom">
                    </li>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="text" name="email" formControlName="email" placeholder="Email">
                    </li>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="password" name="password" formControlName="password" placeholder="Mot de passe">
                    </li>
                </ul>
            </div>

            <div>
                <p class="font-bold text-32 mb-15 mt-10">Il s'agit de</p>
                <select class="border-gray-light border-1 rounded-16 p-12 bg-white min-w-300" name="role" formControlName="role" id="">
                    <option value="companies_manager">Une entreprise</option>
                    <option value="organizations_manager">Un organisme</option>
                </select>
            </div>

            <div>
                <p class="font-bold text-32 mb-15 mt-10">Votre structure</p>

                <p class="text-gray-200 mb-15 max-w-300 md:max-w-none">Vous pourrez changer ces informations une fois votre compte créé.</p>

                <ul>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="text" name="relation_name" formControlName="relation_name" placeholder="Nom de la structure">
                    </li>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="text" name="siret" formControlName="siret" placeholder="N° Siret">
                    </li>
                    <li class="mb-15">
                        <input class="border-gray-light border-1 rounded-16 p-12 min-w-300" type="text" name="description" formControlName="description" placeholder="Description">
                    </li>
                </ul>
            </div>

            <button
                oldUiButton
                (click)="submit()"
                class="mt-30 font-medium bg-primary text-white"
                [ngClass]="{'!bg-primary-light text-black': signinForm.invalid}"
                [disabled]="signinForm.invalid"
                >
                Créer mon compte
            </button>
        </div>

    </form>

    <div class="bg-white rounded-32 border-secondary-variant border-1 p-32 mb-70 md:mb-0">
        <p class="font-bold text-20 mb-16">Vous avez déjà un compte ?</p>
        <a [href]="loginUrl" class="font-bold text-primary text-14 align-middle">Connectez-vous par ici</a>
    </div>
</section>
